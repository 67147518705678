<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar :navigation="items" />
</template>

<script lang="ts">
import {
  Cog6ToothIcon,
  HomeIcon,
  PlayIcon,
  PresentationChartLineIcon,
  ServerIcon,
  SwatchIcon,
} from '@heroicons/vue/24/outline';
import { defineComponent, onMounted, ref } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';
import CustomApplicationFieldsService from '../../services/configuration/CustomApplicationFieldsService';

const baseNavigation = [
  {
    name: 'Overview',
    route: 'ApplicationOverview',
    icon: HomeIcon,
  },
  {
    name: 'Subscriptions',
    route: 'ApplicationSubscriptions',
    icon: PlayIcon,
  },
  {
    name: 'Analytics',
    route: 'ApplicationAnalytics',
    icon: PresentationChartLineIcon,
  },
  {
    name: 'Deployments',
    route: 'ApplicationDeployments',
    icon: ServerIcon,
  },
];

const settings = {
  name: 'Settings',
  route: 'ApplicationSettings',
  icon: Cog6ToothIcon,
};

const customFields = {
  name: 'Custom Fields',
  route: 'ApplicationCustomFields',
  icon: SwatchIcon,
};

export default defineComponent({
  components: { BaseSidebar, SidebarCaption },
  setup() {
    const items = ref<
      {
        name: string;
        route: string;
        icon: any;
      }[]
    >([]);
    onMounted(async () => {
      items.value.push(...baseNavigation);

      const tmpCustomFields = await CustomApplicationFieldsService.getAll();
      if (tmpCustomFields.length > 0) {
        items.value.push({ ...customFields });
      }

      items.value.push({ ...settings });
    });
    return {
      items,
    };
  },
});
</script>
