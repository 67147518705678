import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';

export default [
  {
    path: '/applications',
    name: 'Application',
    component: () => import('../views/EmptyPage.vue'),
    meta: { isEntity: true },
    beforeEnter: async (to, from, next) => {
      if (RoleGuards.isBillingContact()) {
        next('/welcome');
      }
      next();
    },
    props: (route) => ({
      ...route.params,
      ...{ applicationId: Number(route.params.applicationId) },
    }),
    children: [
      {
        path: '',
        name: 'Applications',
        component: () => import('../views/applications/ApplicationList.vue'),
      },
      {
        path: ':applicationId',
        name: 'Application',
        component: () => import('../views/applications/ApplicationDetails.vue'),
        children: [
          {
            path: '',
            name: 'ApplicationOverview',
            component: () => import('../views/applications/ApplicationOverview.vue'),
          },
          {
            path: 'subscriptions',
            name: 'ApplicationSubscriptions',
            component: () => import('../views/applications/ApplicationSubscriptions.vue'),
          },
          {
            path: 'analytics',
            name: 'ApplicationAnalytics',
            component: () => import('../views/applications/ApplicationAnalytics.vue'),
          },
          {
            path: 'deployments',
            name: 'ApplicationDeployments',
            component: () => import('../views/applications/ApplicationDeployments.vue'),
          },
          {
            path: 'customFields',
            name: 'ApplicationCustomFields',
            component: () => import('../views/applications/ApplicationCustomFields.vue'),
          },
          {
            path: 'settings',
            name: 'ApplicationSettings',
            component: () => import('../views/applications/ApplicationSettings.vue'),
          },
        ] as RouteRecordRaw[],
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
