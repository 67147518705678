<template>
  <MultiColumnLayout :hide-bar-by-small-resolution="hideBarBySmallResolution">
    <template #sidebar>
      <!-- Top-Bar with information on the API-->

      <ImageAvatar
        v-if="apiProduct"
        :title="apiProduct.displayName"
        :src="image"
        :default-avatar="getRandomRocketByID(apiProduct.id)"
        text-classes="w-40"
      >
        <template #subtitle>
          <p class="text-sm text-gray-500 w-40 overflow-hidden whitespace-nowrap text-ellipsis">
            <DeploymentIndicator
              :status="apiProduct.deploymentStatus"
              :show-deploy-status-text="true"
            />
          </p>
        </template>
      </ImageAvatar>

      <DeploymentSidebarBanner
        v-if="apiProduct"
        :status="apiProduct.deploymentStatus"
        :to="{
          name: 'ApiProductOverview',
          params: {
            apiProductId: apiProductId,
          },
        }"
      />

      <ApiProductSidebar />
    </template>

    <slot />
  </MultiColumnLayout>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { getRandomRocketByID, ImageAvatar } from '@apiida/vue-components';
import ApiProductService from '../../services/apiProducts/ApiProductService';
import ApiProductSidebar from './ApiProductSidebar.vue';
import DeploymentSidebarBanner from '../common/DeploymentSidebarBanner.vue';
import apiProductStore from '../../stores/ApiProductStore';
import DeploymentIndicator from '../app/DeploymentIndicator.vue';

export default defineComponent({
  components: {
    DeploymentIndicator,
    ApiProductSidebar,
    ImageAvatar,
    DeploymentSidebarBanner,
  },
  props: {
    apiProductId: {
      type: Number,
      required: true,
    },
    propImage: {
      type: String,
      default: '',
    },
    hideBarBySmallResolution: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const image = ref('');
    const apiPStore = apiProductStore();

    async function loadImage() {
      await ApiProductService.getImage(props.apiProductId, false, (base64Image: string) => {
        image.value = base64Image;
      });
    }

    const apiProduct = computed(() => apiPStore.getApiProductByDI(props.apiProductId));

    onMounted(async () => {
      loadImage();
    });

    watch(
      () => props.propImage,
      (newValue) => {
        image.value = newValue;
      }
    );

    return {
      getRandomRocketByID,
      apiProduct,
      image,
      loadImage,
    };
  },
});
</script>
