import { AxiosResponse } from 'axios';
import {
  ApiClientHelper,
  ApprovalRequestsConfig,
  GeneralConfiguration,
} from '@apiida/controlplane-api-helper';
import SmtpConfiguration from '../../types/configuration/SmtpConfiguration';
import ApiClient from '../ApiClient';
import mainStore from '../../stores/MainStore';
import SamlConfiguration from '../../types/configuration/SamlConfiguration';
import LdapConfiguration from '../../types/configuration/LdapConfiguration';
import NotifyCenter from '../NotifyCenter';
import { NamingStrategyEnum } from '../../types/configuration/advancedSettings/NamingStrategyEnum';
import DiscoveryConfig from '../../types/configuration/advancedSettings/DiscoveryConfig';

class ConfigurationService {
  DUMMY_PASSWORD = 'ThisIsNotThePassword';

  getStore() {
    // @ts-ignore
    return mainStore(this.$pinia);
  }

  async getSmtpConfiguration(): Promise<SmtpConfiguration | null> {
    const response = await ApiClient.get<SmtpConfiguration>('/configuration/smtp');

    const configuration = response !== null ? response.data : null;
    if (configuration !== null) {
      configuration.password = this.DUMMY_PASSWORD;
    }
    return configuration;
  }

  async updateSmtpConfiguration(configuration: SmtpConfiguration): Promise<boolean> {
    if (configuration.password === this.DUMMY_PASSWORD) {
      configuration.password = '';
    }

    const response = await ApiClient.put<SmtpConfiguration>('/configuration/smtp', configuration);

    if (ApiClientHelper.isSuccess(response)) {
      NotifyCenter.success('Configuration saved!', 'The changed configuration is now active!');

      return false;
    }

    return true;
  }

  async testSmtpConfiguration(
    recipient: string,
    configuration: SmtpConfiguration
  ): Promise<boolean> {
    if (configuration.password === this.DUMMY_PASSWORD) {
      configuration.password = '';
    }

    const response = await ApiClient.post<AxiosResponse>('/configuration/smtp/test', {
      recipient,
      configuration,
    });

    return ApiClientHelper.isSuccess(response);
  }

  async getGeneralConfiguration(): Promise<GeneralConfiguration | null> {
    const response = await ApiClient.get<GeneralConfiguration>('/configuration/general');

    return response !== null ? response.data : null;
  }

  async updateGeneralConfiguration(configuration: GeneralConfiguration): Promise<boolean> {
    const response = await ApiClient.put<GeneralConfiguration>(
      '/configuration/general',
      configuration
    );

    if (ApiClientHelper.isSuccess(response)) {
      this.storeConfig(configuration);
      NotifyCenter.success('Configuration saved!', 'The changed configuration is now active!');

      return false;
    }
    return true;
  }

  async getApprovalRequestsConfig(): Promise<ApprovalRequestsConfig | null> {
    const response = await ApiClient.get<ApprovalRequestsConfig>('/configuration/approvalRequests');
    return response !== null ? response.data : null;
  }

  async updateApprovalRequestsConfig(configuration: ApprovalRequestsConfig): Promise<boolean> {
    const response = await ApiClient.put<ApprovalRequestsConfig>(
      '/configuration/approvalRequests',
      configuration
    );

    if (ApiClientHelper.isSuccess(response)) {
      NotifyCenter.success('Configuration saved!', 'The changed configuration is now active!');

      return false;
    }
    return true;
  }

  // region SAML

  async getSamlConfiguration(): Promise<SamlConfiguration | null> {
    const response = await ApiClient.get<SamlConfiguration>('/configuration/saml2');

    return response !== null ? response.data : null;
  }

  async updateSamlConfiguration(configuration: SamlConfiguration): Promise<boolean> {
    const response = await ApiClient.put<SamlConfiguration>('/configuration/saml2', configuration);

    if (ApiClientHelper.isSuccess(response)) {
      NotifyCenter.success('Configuration saved!', 'The changed SAML configuration is now active!');

      return true;
    }

    return false;
  }

  // endregion

  // region LDAP

  async getLdapConfiguration(): Promise<LdapConfiguration | null> {
    const response = await ApiClient.get<LdapConfiguration>('/configuration/ldap');

    return response !== null ? response.data : null;
  }

  async updateLdapConfiguration(configuration: LdapConfiguration): Promise<boolean> {
    const response = await ApiClient.put<LdapConfiguration>('/configuration/ldap', configuration);

    if (ApiClientHelper.isSuccess(response)) {
      NotifyCenter.success('Configuration saved!', 'The changed LDAP configuration is now active!');
      return true;
    }
    return false;
  }

  async testLdapConfiguration(configuration: LdapConfiguration): Promise<void> {
    const response = await ApiClient.post<LdapConfiguration>(
      '/configuration/ldap/test',
      configuration
    );

    if (ApiClientHelper.isSuccess(response)) {
      NotifyCenter.success('LDAP test successful!', 'The current configuration is correct!');
    }
  }

  // endregion

  // region DiscoverySettings
  async getDiscoverySettingsConfiguration(): Promise<DiscoveryConfig | null> {
    const response = await ApiClient.get<DiscoveryConfig>('/configuration/discovery');

    return response !== null ? response.data : null;
  }

  async updateDiscoverySettingsConfiguration(data: DiscoveryConfig): Promise<boolean> {
    const response = await ApiClient.put<NamingStrategyEnum>('/configuration/discovery', data);

    if (ApiClientHelper.isSuccess(response)) {
      NotifyCenter.success(
        'Configuration saved!',
        'The changed naming strategy configuration is now active!'
      );
      return true;
    }
    return false;
  }
  // endregion

  storeConfig(config: GeneralConfiguration) {
    this.getStore().config = config;
  }
}

export default new ConfigurationService();
