<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar id="IntegrationsSidebar" :navigation="navigation" />
</template>

<script lang="ts">
import {
  BookOpenIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  DocumentTextIcon,
  EyeIcon,
  HomeIcon,
  PlayIcon,
  ServerIcon,
  Square3Stack3DIcon,
} from '@heroicons/vue/24/outline';
import { defineComponent } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';

const navigation = [
  {
    name: 'Overview',
    route: 'ApiProductOverview',
    icon: HomeIcon,
  },
  {
    name: 'Assigned APIs',
    route: 'ApiProductAssignedApis',
    icon: Square3Stack3DIcon,
  },
  {
    name: 'Visibility',
    route: 'ApiProductVisibility',
    icon: EyeIcon,
  },
  {
    name: 'Deployments',
    route: 'ApiProductDeployments',
    icon: ServerIcon,
  },
  {
    name: 'Plans',
    route: 'ApiProductPlans',
    icon: ClipboardDocumentIcon,
  },
  {
    name: 'Subscriptions',
    route: 'ApiProductSubscriptions',
    icon: PlayIcon,
  },
  {
    name: 'Documentation',
    route: 'ApiProductDocRootPage',
    icon: BookOpenIcon,
  },
  {
    name: 'Terms of Use',
    route: 'ApiProductTermsOfUse',
    icon: DocumentTextIcon,
  },
  {
    name: 'Settings',
    route: 'ApiProductSettings',
    icon: Cog6ToothIcon,
  },
];

export default defineComponent({
  components: {
    BaseSidebar,
    SidebarCaption,
  },
  setup() {
    return {
      navigation,
    };
  },
});
</script>
