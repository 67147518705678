import './index.css';
import '@boomi/exosphere/dist/styles.css';
import '@boomi/exosphere';
import { createApp } from 'vue';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import Notifications from 'notiwind';
import { createPinia } from 'pinia';
import {
  BaseButton,
  BaseCard,
  BaseCell,
  BaseDialog,
  BaseInput,
  BaseTable,
  ButtonNewEntityDialog,
  DeleteConfirmButton,
  ListInput,
  MultiColumnLayout,
  RafikiDynamic,
  PageLayout,
  PrimaryButton,
  SaveButton,
  SingleColumnLayout,
  UpperTitle,
} from '@apiida/vue-components';
import App from './App.vue';
import router from './routers/Router';
import ObsidianPageHeader from './components/app/ObsidianPageHeader.vue';
import config from './config';
import DropdownSettings from './components/app/DropdownSettings.vue';

// Others
const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
// @ts-ignore
app.use(Notifications);

// Application wide components
// base
app.component('PrimaryButton', PrimaryButton);
app.component('RafikiDynamic', RafikiDynamic);
app.component('SaveButton', SaveButton);
app.component('BaseButton', BaseButton);
app.component('BaseCard', BaseCard);
app.component('BaseInput', BaseInput);
app.component('ListInput', ListInput);
app.component('BaseDialog', BaseDialog);
app.component('BaseTable', BaseTable);
app.component('BaseCell', BaseCell);
app.component('BounceLoader', BounceLoader);
app.component('BeatLoader', BeatLoader);
app.component('UpperTitle', UpperTitle);
app.component('PacmanLoader', PacmanLoader);
app.component('MultiColumnLayout', MultiColumnLayout);
app.component('SingleColumnLayout', SingleColumnLayout);
app.component('PageLayout', PageLayout);
app.component('ObsidianPageHeader', ObsidianPageHeader);
// extended
app.component('ButtonNewEntityDialog', ButtonNewEntityDialog);
app.component('DeleteConfirmButton', DeleteConfirmButton);
app.component('DropdownSettings', DropdownSettings);

console.log(`Update the status of the agents every ${+config.reloadAgentStatus / 1000}s`);

app.mount('#app');
