import { defineStore } from 'pinia';
import TermOfUse from '../types/configuration/TermOfUse';

const termOfUseStore = defineStore({
  id: 'termOfUse',
  state: () => ({
    termsOfUse: [] as TermOfUse[],
  }),
  actions: {
    getTermOfUseByDI(termOfUseId: number): TermOfUse | undefined {
      const o: TermOfUse | undefined = this.termsOfUse.find(
        (termOfUseInArray: TermOfUse) => Number(termOfUseInArray.id) === Number(termOfUseId)
      );
      return o;
    },
  },
});

export default termOfUseStore;
