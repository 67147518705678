import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';
import SaaSGuard from './guards/SaaSGuard';

export default [
  {
    path: '/accountSettings',
    name: 'AccountSettings',
    meta: { BreadcrumbName: 'Account Settings' },
    component: () => import('../views/configuration/users/AccountSettings.vue'),
  },
  {
    path: '/configuration',
    name: 'Configuration',
    component: () => import('../views/configuration/TheConfigurationRouterView.vue'),
    beforeEnter: RoleGuards.isAdminOrGlobalAdminOrBilling,
    children: [
      {
        path: '',
        name: 'ConfigurationGeneral',
        component: () => import('../views/configuration/ConfigurationGeneral.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
      },
      {
        path: 'customization',
        name: 'ConfigurationCustomization',
        component: () => import('../views/configuration/ConfigurationCustomization.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
      },
      {
        path: 'smtp',
        name: 'ConfigurationSMTP',
        meta: { BreadcrumbName: 'SMTP' },
        component: () => import('../views/configuration/ConfigurationSMTP.vue'),
        beforeEnter: [RoleGuards.isGlobalAdmin, SaaSGuard.isNotSaas],
      },
      {
        path: 'devPortals',
        name: 'ConfigurationDevPortal',
        component: () => import('../views/EmptyPage.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
        meta: { isEntity: true, BreadcrumbName: 'Developer Portals' },
        props: (route) => ({
          ...route.params,
          ...{ id: Number(route.params.id) },
        }),
        children: [
          {
            path: '',
            name: 'ConfigurationDevPortalIndex',
            component: () => import('../views/configuration/devPortal/ConfigurationDevPortal.vue'),
          },
          {
            path: ':id',
            name: 'ConfigurationDevPortalRouterView',
            component: () =>
              import('../views/configuration/devPortal/ConfigurationDevPortalRouterView.vue'),
            props: true,
            children: [
              {
                name: 'redirectToConfigurationDevPortalSettings',
                path: '',
                redirect: (to) => ({
                  name: 'ConfigurationDevPortalSettings',
                  params: { id: to.params.id },
                }),
              },
              {
                path: 'customization',
                name: 'ConfigurationDevPortalCustomization',
                component: () =>
                  import(
                    '../views/configuration/devPortal/cms/ConfigurationDevPortalCustomization.vue'
                  ),
                props: true,
              },
              {
                path: 'menu',
                name: 'ConfigurationDevPortalMenu',
                component: () =>
                  import('../views/configuration/devPortal/cms/ConfigurationDevPortalMenu.vue'),
                props: true,
              },
              {
                path: 'pages',
                name: 'ConfigurationDevPortalPages',
                component: () =>
                  import('../views/configuration/devPortal/cms/ConfigurationDevPortalPages.vue'),
                props: true,
              },
              {
                path: 'settings',
                name: 'ConfigurationDevPortalSettings',
                component: () =>
                  import('../views/configuration/devPortal/ConfigurationDevPortalSettings.vue'),
                meta: { BreadcrumbName: 'General Info' },
              },
              {
                path: 'termsOfUse',
                name: 'ConfigurationDevPortalTermsOfUse',
                component: () =>
                  import('../views/configuration/devPortal/ConfigurationDevPortalTermsOfUse.vue'),
                meta: { BreadcrumbName: 'Terms of Use' },
              },
            ],
          },
        ] as RouteRecordRaw[],
      },
      {
        path: 'rulesets',
        name: 'ConfigurationRuleSets',
        component: () => import('../views/EmptyPage.vue'),
        beforeEnter: [RoleGuards.isGlobalAdmin],
        meta: { isEntity: true },
        children: [
          {
            path: '',
            name: 'ConfigurationRuleSetsIndex',
            component: () => import('../views/configuration/ruleSets/ConfigurationRuleSets.vue'),
          },
          {
            path: ':id',
            name: 'ConfigurationRuleSetRouterView',
            component: () =>
              import('../views/configuration/ruleSets/ConfigurationRuleSetRouterView.vue'),
            props: true,
            children: [
              {
                name: 'redirectToConfigurationRuleSetRouterView',
                path: '',
                redirect: (to) => ({
                  name: 'ConfigurationRuleSetRouterView',
                  params: { id: to.params.id },
                }),
              },
              {
                path: 'details',
                name: 'ConfigurationRuleSetDetails',
                component: () =>
                  import('../views/configuration/ruleSets/ConfigurationRuleSetDetails.vue'),
                props: true,
              },
              {
                path: 'organizations',
                name: 'ConfigurationRuleSetOrganizations',
                component: () =>
                  import('../views/configuration/ruleSets/ConfigurationRuleSetOrganizations.vue'),
                props: true,
              },
              {
                path: 'file',
                name: 'ConfigurationRuleSetFile',
                component: () =>
                  import('../views/configuration/ruleSets/ConfigurationRuleSetFile.vue'),

                props: true,
              },
              {
                path: 'rules',
                name: 'ConfigurationRuleSetRules',
                component: () =>
                  import('../views/configuration/ruleSets/ConfigurationRuleSetRules.vue'),

                props: true,
              },
            ],
          },
        ] as RouteRecordRaw[],
      },
      {
        path: 'termsOfUse',
        name: 'ConfigurationTermsOfUse',
        component: () => import('../views/EmptyPage.vue'),
        beforeEnter: [RoleGuards.isGlobalAdmin],
        meta: { isEntity: true },
        props: (route) => ({
          ...route.params,
          ...{ id: Number(route.params.id) },
        }),
        children: [
          {
            path: '',
            name: 'ConfigurationTermsOfUseIndex',
            component: () =>
              import('../views/configuration/termsOfUse/ConfigurationTermsOfUse.vue'),
          },
          {
            path: ':id',
            name: 'ConfigurationTermsOfUseRouterView',
            component: () =>
              import('../views/configuration/termsOfUse/ConfigurationTermsOfUseRouterView.vue'),
            props: true,
            children: [
              {
                name: 'redirectToConfigurationTermsOfUseRouterVieww',
                path: '',
                redirect: (to) => ({
                  name: 'ConfigurationTermsOfUseRouterView',
                  params: { id: to.params.id },
                }),
              },
              {
                path: 'settings',
                name: 'ConfigurationTermsOfUseSettings',
                component: () =>
                  import('../views/configuration/termsOfUse/ConfigurationTermsOfUseSettings.vue'),
                props: true,
              },
              {
                path: 'versions',
                name: 'ConfigurationTermsOfUseVersions',
                component: () =>
                  import('../views/configuration/termsOfUse/ConfigurationTermsOfUseVersions.vue'),
                props: true,
              },
            ],
          },
        ] as RouteRecordRaw[],
      },
      {
        path: 'auditLogs',
        name: 'ConfigurationAuditLogs',
        meta: { BreadcrumbName: 'Audit Logs' },
        component: () => import('../views/configuration/auditLogs/ConfigurationAuditLogs.vue'),
      },
      {
        path: 'gitCredentials',
        name: 'ConfigurationGitCredentials',
        meta: { isEntity: true, BreadcrumbName: 'Git Credentials' },
        component: () => import('../views/EmptyPage.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
        children: [
          {
            path: '',
            name: 'ConfigurationGitCredentialsIndex',
            component: () =>
              import('../views/configuration/gitCredentials/ConfigurationGitCredentials.vue'),
          },
          {
            path: ':id',
            name: 'ConfigurationGitCredentialsDetails',
            component: () =>
              import(
                '../views/configuration/gitCredentials/ConfigurationGitCredentialsDetails.vue'
              ),
          },
        ] as RouteRecordRaw[],
      },
      {
        path: 'saml',
        name: 'ConfigurationSaml',
        meta: { BreadcrumbName: 'SAML' },
        component: () => import('../views/configuration/ConfigurationSaml.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
      },
      {
        path: 'ldap',
        name: 'ConfigurationLdap',
        meta: { BreadcrumbName: 'LDAP' },
        component: () => import('../views/configuration/ConfigurationLdap.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
      },
      {
        path: 'users',
        name: 'ConfigurationUsers',
        component: () => import('../views/EmptyPage.vue'),
        beforeEnter: [RoleGuards.isAdminOrGlobalAdmin],
        meta: { isEntity: true },
        children: [
          {
            path: '',
            name: 'ConfigurationUsersIndex',
            component: () => import('../views/configuration/users/ConfigurationUsers.vue'),
          },
          {
            path: ':id',
            name: 'ConfigurationUsersDetails',
            component: () => import('../views/configuration/users/ConfigurationUsersDetails.vue'),
          },
        ] as RouteRecordRaw[],
      },
      {
        path: 'organizations',
        name: 'ConfigurationOrganizations',
        component: () => import('../views/EmptyPage.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
        meta: { isEntity: true },
        props: (route) => ({
          ...route.params,
          ...{ id: Number(route.params.id) },
        }),
        children: [
          {
            path: '',
            name: 'ConfigurationOrganizationsIndex',
            component: () =>
              import('../views/configuration/organization/ConfigurationOrganizations.vue'),
          },
          {
            path: ':id',
            name: 'ConfigurationOrganizationsDetails',
            component: () =>
              import('../views/configuration/organization/ConfigurationOrganizationsDetails.vue'),
            props: true,
            children: [
              {
                name: 'redirectToConfigurationOrganizationsSettings',
                path: '',
                redirect: (to) => ({
                  name: 'ConfigurationOrganizationsSettings',
                  params: { id: to.params.id },
                }),
              },
              {
                path: 'users',
                name: 'ConfigurationOrganizationsUsers',
                component: () =>
                  import('../views/configuration/organization/ConfigurationOrganizationsUsers.vue'),
                props: (route) => ({
                  ...route.params,
                  ...{ id: Number(route.params.id) },
                }),
              },
              {
                path: 'publishedEnvironments',
                name: 'ConfigurationOrganizationsPublishedEnvironments',
                component: () =>
                  import(
                    '../views/configuration/organization/ConfigurationOrganizationsPublishedEnvironments.vue'
                  ),
                props: (route) => ({
                  ...route.params,
                  ...{ id: Number(route.params.id) },
                }),
              },
              {
                path: 'subscribedEnvironments',
                name: 'ConfigurationOrganizationsSubscribedEnvironments',
                component: () =>
                  import(
                    '../views/configuration/organization/ConfigurationOrganizationsSubscribedEnvironments.vue'
                  ),
                props: (route) => ({
                  ...route.params,
                  ...{ id: Number(route.params.id) },
                }),
              },
              {
                path: 'suborganizations',
                name: 'ConfigurationOrganizationsSuborganizations',
                component: () =>
                  import(
                    '../views/configuration/organization/ConfigurationOrganizationsSuborganizations.vue'
                  ),
                props: (route) => ({
                  ...route.params,
                  ...{ id: Number(route.params.id) },
                }),
              },
              {
                path: 'settings',
                name: 'ConfigurationOrganizationsSettings',
                component: () =>
                  import(
                    '../views/configuration/organization/ConfigurationOrganizationsSettings.vue'
                  ),
                props: (route) => ({
                  ...route.params,
                  ...{ id: Number(route.params.id) },
                }),
              },
            ],
          },
        ] as RouteRecordRaw[],
      },
      {
        path: 'approval',
        name: 'ConfigurationApprovals',
        component: () => import('../views/configuration/ConfigurationApprovals.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
      },
      {
        path: 'plans',
        name: 'ConfigurationPlans',
        component: () => import('../views/EmptyPage.vue'),
        meta: { isEntity: true },
        children: [
          {
            path: '',
            name: 'ConfigurationPlansIndex',
            component: () => import('../views/configuration/plans/ConfigurationPlans.vue'),
          },
          {
            path: ':id',
            name: 'ConfigurationPlansRouterView',
            component: () =>
              import('../views/configuration/plans/ConfigurationPlansRouterView.vue'),
            props: (route) => ({
              ...route.params,
              ...{ id: Number(route.params.id) },
            }),
            children: [
              {
                name: 'redirectToConfigurationPlansDetails',
                path: '',
                redirect: (to) => ({
                  name: 'ConfigurationPlansDetails',
                  params: { id: to.params.id },
                }),
              },
              {
                path: 'details',
                name: 'ConfigurationPlansDetails',
                component: () =>
                  import('../views/configuration/plans/ConfigurationPlansDetails.vue'),
              },
              {
                path: 'apiProducts',
                name: 'ConfigurationPlansApiProducts',
                component: () =>
                  import('../views/configuration/plans/ConfigurationPlansApiProducts.vue'),
              },
              {
                path: 'deployments',
                name: 'ConfigurationPlansDeployments',
                component: () =>
                  import('../views/configuration/plans/ConfigurationPlansDeployments.vue'),
              },
            ],
          },
        ] as RouteRecordRaw[],
      },

      /* TODO: config.enableTierAndBilling */
      /*       {
        path: 'billingInformation',
        name: 'ConfigurationBillingInformation',
        meta: { BreadcrumbName: 'Billing Information' },
        component: () =>
          import('../views/configuration/billing/ConfigurationBillingInformation.vue'),
        beforeEnter: [RoleGuards.isGlobalAdminOrBilling, SaaSGuard.isSaas],
      },
      {
        path: '',
        name: 'ConfigurationTierManagementRouterView',
        component: () =>
          import('../views/configuration/tierManagement/ConfigurationTierManagementRouterView.vue'),
        props: true,
        children: [
          {
            path: 'tierOverview',
            name: 'ConfigurationTierOverview',
            meta: { BreadcrumbName: 'Tier Overview' },
            component: () =>
              import('../views/configuration/tierManagement/ConfigurationTierOverview.vue'),
            beforeEnter: [RoleGuards.isGlobalAdminOrBilling, SaaSGuard.isSaas],
          },
          {
            path: 'tierManagement',
            name: 'ConfigurationTierManagement',
            meta: { BreadcrumbName: 'Tier Management' },
            component: () =>
              import('../views/configuration/tierManagement/ConfigurationTierManagement.vue'),
            beforeEnter: [RoleGuards.isGlobalAdminOrBilling, SaaSGuard.isSaas],
          },
        ],
      }, */

      {
        path: 'customFields',
        name: 'ConfigurationCustomApplicationFields',
        meta: { BreadcrumbName: 'Custom Fields' },
        component: () =>
          import(
            '../views/configuration/advancedSettings/ConfigurationCustomApplicationFields.vue'
          ),
        beforeEnter: [RoleGuards.isGlobalAdmin],
      },
      {
        path: 'discoverySettings',
        name: 'ConfigurationDiscoverySettings',
        meta: { BreadcrumbName: 'Discovery Settings' },
        component: () =>
          import('../views/configuration/advancedSettings/ConfigurationDiscoverySettings.vue'),
        beforeEnter: [RoleGuards.isGlobalAdmin],
      },

      {
        path: 'webhooks',
        name: 'ConfigurationWebhooks',
        component: () => import('../views/EmptyPage.vue'),
        beforeEnter: RoleGuards.isGlobalAdmin,
        meta: { isEntity: true, BreadcrumbName: 'Webhooks' },
        props: (route) => ({
          ...route.params,
          ...{ id: Number(route.params.id) },
        }),
        children: [
          {
            path: '',
            name: 'ConfigurationWebhooksList',
            component: () =>
              import(
                '../views/configuration/advancedSettings/webhooks/ConfigurationWebhooksList.vue'
              ),
          },
          {
            path: ':id',
            name: 'ConfigurationWebhooksRouterView',
            component: () =>
              import(
                '../views/configuration/advancedSettings/webhooks/ConfigurationWebhooksRouterView.vue'
              ),
            props: true,
            children: [
              {
                name: 'redirectToConfigurationWebhooksDetails',
                path: '',
                redirect: (to) => ({
                  name: 'ConfigurationWebhooksDetails',
                  params: { id: to.params.id },
                }),
              },
              {
                path: 'details',
                name: 'ConfigurationWebhooksDetails',
                component: () =>
                  import(
                    '../views/configuration/advancedSettings/webhooks/ConfigurationWebhooksDetails.vue'
                  ),
                props: true,
              },
              {
                path: 'logs',
                name: 'ConfigurationWebhooksLog',
                component: () =>
                  import(
                    '../views/configuration/advancedSettings/webhooks/ConfigurationWebhooksLog.vue'
                  ),
                meta: { BreadcrumbName: 'Logs' },
              },
            ],
          },
        ] as RouteRecordRaw[],
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
