import {
  BasicType,
  PaginationService as ThePaginationService,
} from '@apiida/controlplane-api-helper';
import apiClient from './ApiClient';

export default abstract class PaginationService<
  T extends BasicType
> extends ThePaginationService<T> {
  protected constructor(url: string, entityName: string) {
    super(url, entityName, apiClient);
  }
}
