<template>
  <MultiColumnLayout :hide-bar-by-small-resolution="hideBarBySmallResolution">
    <template #sidebar>
      <ImageAvatar
        v-if="application"
        :title="application.displayName"
        :src="image"
        :default-avatar="getRandomRocketByID(application.id)"
      >
        <template #subtitle>
          <p class="text-sm text-gray-500 w-40 overflow-hidden whitespace-nowrap text-ellipsis">
            <DeploymentIndicator
              :status="application.deploymentStatus"
              :show-deploy-status-text="true"
            />
          </p>
        </template>
      </ImageAvatar>

      <DeploymentSidebarBanner
        v-if="application"
        :status="application.deploymentStatus"
        :to="{
          name: 'ApplicationOverview',
          params: {
            applicationId: applicationId,
          },
        }"
      />

      <InActiveSidebarBanner
        v-if="application"
        entity="Application"
        :status="application.status"
        :to="{
          name: 'ApplicationSettings',
          params: {
            applicationId: applicationId,
          },
        }"
      />

      <ApplicationSidebar />
    </template>

    <slot />
  </MultiColumnLayout>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { getRandomRocketByID, ImageAvatar } from '@apiida/vue-components';
import ApplicationService from '../../services/applications/ApplicationService';
import ApplicationSidebar from './ApplicationSidebar.vue';
import DeploymentSidebarBanner from '../common/DeploymentSidebarBanner.vue';
import DeploymentIndicator from '../app/DeploymentIndicator.vue';
import applicationStore from '../../stores/ApplicationStore';
import InActiveSidebarBanner from '../common/InActiveSidebarBanner.vue';

export default defineComponent({
  components: {
    InActiveSidebarBanner,
    ApplicationSidebar,
    ImageAvatar,
    DeploymentSidebarBanner,
    DeploymentIndicator,
  },
  props: {
    applicationId: {
      type: Number,
      required: true,
    },
    propImage: {
      type: String,
      default: '',
    },
    hideBarBySmallResolution: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const image = ref('');
    const appStore = applicationStore();

    async function loadImage(forceReload: boolean = false) {
      await ApplicationService.getImage(props.applicationId, forceReload, (base64Image: string) => {
        image.value = base64Image;
      });
    }

    const application = computed(() => appStore.getApplicationByDI(props.applicationId));

    onMounted(async () => {
      loadImage();
    });

    watch(
      () => props.propImage,
      (newValue) => {
        image.value = newValue;
      }
    );

    return { getRandomRocketByID, application, image, loadImage };
  },
});
</script>
