<template>
  <PageHeader
    :title="title"
    :subtitle="subtitle"
    :show-search="showSearch"
    @update-filter="updateFilter"
  >
    <template #Breadcrumbs>
      <div class="flex">
        <BreadcrumbList />
        <slot name="expandedBreadcrumb" />
      </div>
    </template>

    <template #buttons>
      <slot name="buttons" />
    </template>
  </PageHeader>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { PageHeader } from '@apiida/vue-components';
import BreadcrumbList from './BreadcrumbList.vue';

export default defineComponent({
  components: {
    BreadcrumbList,
    PageHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-filter'],
  setup(props, { emit }) {
    function updateFilter(searchText: string) {
      emit('update-filter', searchText);
    }

    return { updateFilter };
  },
});
</script>
