import { defineStore } from 'pinia';
import Application from '../types/applications/Application';

const applicationStore = defineStore({
  id: 'application',
  state: () => ({
    applications: [] as Application[],
    images: [] as { applicationId: number; image: string }[],
  }),
  getters: {
    getApplications(): Application[] {
      return this.applications;
    },
  },
  actions: {
    getApplicationByDI(applicationId: number): Application | undefined {
      const application: Application | undefined = this.applications.find(
        (appInArray: Application) => Number(appInArray.id) === Number(applicationId)
      );
      return application;
    },
    getImage(applicationId: number): string | undefined {
      const applicationImage: { applicationId: number; image: string } | undefined =
        this.images.find(
          (aI: { applicationId: number; image: string }) =>
            Number(aI.applicationId) === Number(applicationId)
        );
      if (applicationImage) {
        return applicationImage.image;
      }
      return undefined;
    },
    storeOrUpdateImage(applicationId: number, image: string): void {
      let storeIt = true;
      this.images.map((aI: { applicationId: number; image: string }) => {
        if (Number(aI.applicationId) === Number(applicationId)) {
          aI.image = image;
          storeIt = false;
        }
        return aI;
      });
      if (storeIt) {
        this.images.push({ applicationId, image });
      }
    },
    deleteImage(applicationId: number): void {
      const indexOfObject = this.images.findIndex(
        (aI: { applicationId: number; image: string }) =>
          Number(aI.applicationId) === Number(applicationId)
      );
      this.images.splice(indexOfObject, 1);
    },
  },
});

export default applicationStore;
