import { defineStore } from 'pinia';
import { BaseNotification, NotificationType } from '@apiida/controlplane-api-helper';

const notificationStore = defineStore({
  id: 'notification',
  state: () => ({
    notifications: [] as BaseNotification[],
  }),
  getters: {
    getNotifications(): BaseNotification[] {
      return this.notifications;
    },
  },
  actions: {
    removeAllNotifications() {
      this.notifications.forEach((not: BaseNotification) => {
        this.removeNotification(not.id);
      });
    },

    expireNotifications(ms: number) {
      this.notifications.forEach((notification: BaseNotification) => {
        // Error messages do not disappear.
        if (notification.type === NotificationType.ERROR) {
          return;
        }
        if (notification?.pauseExpire === true) {
          return;
        }

        notification.showUntil -= ms;
      });

      this.notifications.forEach((not: BaseNotification) => {
        if (not.showUntil <= 0) {
          if (not.id) this.removeNotification(not.id);
        }
      });
    },
    removeNotification(id: number | undefined) {
      if (id === undefined) return;
      const indexOfObject = this.notifications.findIndex(
        (aI: BaseNotification) => Number(aI.id) === Number(id)
      );
      this.notifications.splice(indexOfObject, 1);
    },
    addNotification(newNotification: BaseNotification): void {
      // init id and counter
      const randomId = Math.floor(Math.random() * 999999);
      newNotification.id = randomId;
      newNotification.counter = 1;

      // Search if it already exists
      const alreadyExists: BaseNotification | undefined = this.notifications.find(
        (storedNotification: BaseNotification) =>
          storedNotification.type === newNotification.type &&
          storedNotification.title === newNotification.title &&
          storedNotification.text === newNotification.text
      );

      if (alreadyExists) {
        // Count up and reset showUntil
        if (alreadyExists.counter) alreadyExists.counter += 1;
        alreadyExists.showUntil = newNotification.showUntil;
      } else {
        // new Notification
        this.notifications.push(newNotification);
      }
    },
  },
});

export default notificationStore;
