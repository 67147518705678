<template>
  <nav class="hidden sm:flex" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center">
      <BreadcrumbItem key="home" :is-root="true" link="/welcome" text="Home" />
      <BreadcrumbItem
        v-for="item in crumbs()"
        :key="item.link"
        :link="item.link"
        :text="item.text"
      />
    </ol>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { BreadcrumbItem } from '@apiida/vue-components';
import environmentStore from '../../stores/EnvironmentStore';
import apiStore from '../../stores/ApiStore';
import apiPStore from '../../stores/ApiProductStore';
import applicationStore from '../../stores/ApplicationStore';
import userStore from '../../stores/UserStore';
import planStore from '../../stores/PlanStore';
import organizationStore from '../../stores/OrganizationStore';
import gitCredentialStore from '../../stores/GitCredentialStore';
import ruleSetStore from '../../stores/RuleSetStore';
import devPortalStore from '../../stores/DevPortalStore';
import Breadcrumb from '../../types/app/Breadcrumb';
import termOfUseStore from '../../stores/TermOfUseStore';
import webhookStore from '../../stores/WebhookStore';

export default defineComponent({
  components: {
    BreadcrumbItem,
  },
  setup() {
    const route = useRoute();
    const envStore = environmentStore();
    const aStore = apiStore();
    const apiProductStore = apiPStore();
    const applicatioStore = applicationStore();
    const useStore = userStore();
    const plaStore = planStore();
    const orgStore = organizationStore();
    const gitStore = gitCredentialStore();
    const ruleStore = ruleSetStore();
    const devPoStore = devPortalStore();
    const touStore = termOfUseStore();
    const myWebhookStore = webhookStore();
    let currentRoute = '';

    function getTo(path: string): string {
      currentRoute += `/${path}`;
      return currentRoute;
    }

    function loadNameFromEntity(idx: number, path: string): string {
      if (idx - 1 >= 0 && route.matched[idx - 1] && route.matched[idx - 1].meta.isEntity) {
        switch (route.matched[idx - 1].name) {
          case 'Environment': {
            const env = envStore.getEnvironmentById(parseInt(path, 10));
            return env ? env.displayName : 'Not loaded yet';
          }
          case 'API': {
            const api = aStore.getAPIByDI(parseInt(path, 10));
            return api ? api.displayName : 'Not loaded yet';
          }
          case 'ApiProduct': {
            const apiProduct = apiProductStore.getApiProductByDI(parseInt(path, 10));
            return apiProduct ? apiProduct.displayName : 'Not loaded yet';
          }
          case 'Application': {
            const application = applicatioStore.getApplicationByDI(parseInt(path, 10));
            return application ? application.displayName : 'Not loaded yet';
          }
          case 'ConfigurationUsers': {
            const user = useStore.getUserByDI(parseInt(path, 10));
            return user ? user.displayName : 'Not loaded yet';
          }
          case 'ConfigurationPlans': {
            const plan = plaStore.getPlanByDI(parseInt(path, 10));
            return plan ? plan.displayName : 'Not loaded yet';
          }
          case 'ConfigurationOrganizations': {
            const organization = orgStore.getOrganizationByDI(parseInt(path, 10));
            return organization ? organization.displayName : 'Not loaded yet';
          }
          case 'ConfigurationGitCredentials': {
            const gitCredentials = gitStore.getGitCredentialByDI(parseInt(path, 10));
            return gitCredentials ? gitCredentials.displayName : 'Not loaded yet';
          }
          case 'ConfigurationRuleSets': {
            const ruleSet = ruleStore.getRuleSetByDI(parseInt(path, 10));
            return ruleSet ? ruleSet.name : 'Not loaded yet';
          }
          case 'ConfigurationDevPortal': {
            const devPortal = devPoStore.getDevPortalByDI(parseInt(path, 10));
            return devPortal ? devPortal.name : 'Not loaded yet';
          }
          case 'ConfigurationTermsOfUse': {
            const tou = touStore.getTermOfUseByDI(parseInt(path, 10));
            return tou ? tou.name : 'Not loaded yet';
          }
          case 'ConfigurationWebhooks': {
            const webhook = myWebhookStore.getWebhockByDI(parseInt(path, 10));
            return webhook ? webhook.displayName : 'Not loaded yet';
          }
          default: {
            console.log(`name ${String(route.matched[idx - 1].name)} not found`);
            return 'Case in BreadcrumbList.vue missing';
          }
        }
      }

      // If the child of a route has a name as the route in the breadcrumbs.
      if (route.matched[idx]) {
        if (route.matched[idx].children && route.matched[idx].children.length > 0) {
          const matchedChild = route.matched[idx].children.find(
            (child) => path === child.path && child.meta?.BreadcrumbName
          );
          if (matchedChild) {
            return String(matchedChild.meta?.BreadcrumbName);
          }
        }
      }

      if (route.matched[idx] && route.matched[idx].meta.BreadcrumbName) {
        return String(route.matched[idx].meta.BreadcrumbName);
      }
      return path.charAt(0).toUpperCase() + path.slice(1);
    }

    function docHandler(arr: string[]): string[] {
      const index = arr.indexOf('docContent');

      if (index !== -1) {
        arr.splice(index, 2);
      }

      return arr;
    }

    function crumbs() {
      currentRoute = '';
      let pathArray = route.path.split('/');

      pathArray.shift();
      pathArray = docHandler(pathArray);

      const breadcrumbs: Array<Breadcrumb> = pathArray.reduce(
        (breadcrumbArray: Array<Breadcrumb>, path: string, idx: number) => {
          breadcrumbArray.push({
            link: getTo(path),
            text: loadNameFromEntity(idx, decodeURI(path)),
          } as Breadcrumb);
          return breadcrumbArray;
        },
        []
      );

      return breadcrumbs;
    }

    return { crumbs };
  },
});
</script>
