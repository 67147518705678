<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar id="IntegrationsSidebar" :navigation="navigation" />
</template>

<script lang="ts">
import {
  BookOpenIcon,
  Cog6ToothIcon,
  CubeTransparentIcon,
  HomeIcon,
  PresentationChartLineIcon,
  ServerIcon,
  Squares2X2Icon,
} from '@heroicons/vue/24/outline';
import { defineComponent } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';

const navigation = [
  {
    name: 'Overview',
    route: 'ApiOverview',
    icon: HomeIcon,
  },
  {
    name: 'API Products',
    route: 'ApiApiProduct',
    icon: CubeTransparentIcon,
  },
  {
    name: 'Versions',
    route: 'ApiVersions',
    icon: Squares2X2Icon,
  },
  {
    name: 'Analytics',
    route: 'ApiAnalytics',
    icon: PresentationChartLineIcon,
  },
  {
    name: 'Deployments',
    route: 'ApiDeployments',
    icon: ServerIcon,
  },
  {
    name: 'Documentation',
    route: 'ApiDocRootPage',
    icon: BookOpenIcon,
  },
  {
    name: 'Settings',
    route: 'ApiSettings',
    icon: Cog6ToothIcon,
  },
];

export default defineComponent({
  components: {
    BaseSidebar,
    SidebarCaption,
  },
  setup() {
    return {
      navigation,
    };
  },
});
</script>
