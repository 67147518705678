import { BaseNotificationCenter } from '@apiida/controlplane-api-helper';
import notificationStore from '../stores/NotificationStore';

class NotifyCenter extends BaseNotificationCenter {
  getStore(): any {
    // @ts-ignore
    return notificationStore(this.$pinia);
  }
}

export default new NotifyCenter();
