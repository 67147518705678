import { TierEnum } from '@apiida/controlplane-api-helper';
import mainStore from '../../stores/MainStore';
import ObService from '../../services/app/ObService';
import SaaSInfo from '../../types/app/SaaSInfo';

async function getSaaSInfo(): Promise<SaaSInfo> {
  let info = mainStore().SaaSInfo;

  // If the SaaSInfo is not defined, we simply load it.
  if (info.isSaaS === undefined) {
    info = await ObService.loadSaaSInfo();
  }
  return info;
}

export default {
  async isNotSaas(): Promise<boolean> {
    return !(await getSaaSInfo()).isSaaS;
  },

  async isSaas(): Promise<boolean> {
    return (await getSaaSInfo()).isSaaS;
  },
  async getTier(): Promise<TierEnum> {
    return (await getSaaSInfo()).tierInfo.tier;
  },
};
