const allowedPaths = ['/auth', '/acceptInvitation', '/resetPassword', '/samlResponse'];

/**
 * is the path part of the allowed paths
 * @param path
 */
function isAllowedPath(path: string): boolean {
  return allowedPaths.includes(path.trim()) || allowedPaths.some((p) => path.startsWith(p));
}

export default isAllowedPath;
