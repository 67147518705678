<template>
  <div class="flex h-8 my-auto pl-1">
    <DangerDialog
      v-if="showDialog"
      id="dropdown"
      :button-do-text="selectItem.btnText"
      :title="selectItem.dialogTitle"
      @close-dialog="showDialog = !showDialog"
      @do-it="selectItem.callback()"
    >
      {{ selectItem.dialogText }}
    </DangerDialog>

    <BaseDropdownButton
      :disabled="disabled"
      :button-type="'Base'"
      :show-boarder="false"
      :menu-width="menuWidth"
      icon="EllipsisHorizontalIcon"
      icon-class="rounded-md text-brand border border-transparent hover:bg-white hover:border-gray-300 hover:text-secondary-700 focus:bg-white focus:ring-gray-300"
      z-index="z-10"
      button-padding=""
      :items="items"
      @item-clicked="selectItemFromList"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { BaseDropdownButton, DangerDialog } from '@apiida/vue-components';
import DropdownSettingType from '../../types/basics/DropdownSettingType';

export default defineComponent({
  components: { DangerDialog, BaseDropdownButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<DropdownSettingType[]>,
      default: () => [],
    },
    menuWidth: {
      type: String,
      default: 'w-40',
    },
  },
  emits: ['do-it'],
  setup(props) {
    const showDialog = ref<boolean>(false);
    const selectItem = ref<DropdownSettingType>();

    function selectItemFromList(id: number) {
      selectItem.value = props.items?.find((item: DropdownSettingType) => item.id === id);

      if (selectItem.value?.showWarning === false || selectItem.value?.showWarning === undefined) {
        selectItem.value?.callback();
      } else {
        showDialog.value = true;
      }
    }

    onMounted(async () => {});
    return {
      showDialog,
      selectItem,
      selectItemFromList,
    };
  },
});
</script>
