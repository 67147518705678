<template>
  <div
    v-if="jobs.length > 0"
    class="flex flex-col justify-center items-center"
    @click="jobsView = true"
  >
    <info-badge :class="lvl1BarOpen ? 'ml-12' : 'ml-auto'">{{ jobs.length }}</info-badge>
    <bounce-loader :loading="true" :color="'#3894DC'" />
  </div>

  <BaseDialog
    v-if="jobsView"
    id="jobViewer"
    title="Job Overview"
    :width="64"
    @close-dialog="jobsView = false"
  >
    <div v-for="job in jobs" :key="job.id">
      <div class="pt-3">
        <h1 class="ml-6 text-lg font-semibold inline-flex">
          {{ job.jobName }}
        </h1>
        <h2 class="ml-6 text-base inline-flex">{{ job.jobStatus }}</h2>
        <h3 class="ml-6 text-sm inline-flex">{{ job.progressMessage }}</h3>
      </div>
      <div class="flex flex-wrap p-4">
        <BaseProgress :indeterminate="true"></BaseProgress>
      </div>
    </div>

    <RafikiDynamic
      :show="jobs.length === 0"
      text="Looks like there are no active jobs at the moment."
    />
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { BaseDialog, BaseProgress, InfoBadge } from '@apiida/vue-components';
import { Job, WebSocketClient } from '@apiida/controlplane-api-helper';
import { insertTenantId } from '../../helper/TenantHelper';
import config from '../../config';

export default defineComponent({
  components: { BaseProgress, BaseDialog, InfoBadge },
  props: {
    lvl1BarOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const jobs = ref<Job[]>([]);
    const jobsView = ref<boolean>(false);

    const token = localStorage.getItem('accessToken')?.replace('Bearer ', '') ?? '';
    const websocketUrl = insertTenantId(`${config.backendWs}/jobsWebsocket?token=${token}`);

    const webSocketClient = new WebSocketClient(websocketUrl);

    onMounted(async () => {
      webSocketClient.connect((jobsArray: Job[]) => {
        jobs.value = jobsArray;
      });
    });

    onUnmounted(() => {
      webSocketClient.disconnect();
    });

    return { jobs, jobsView };
  },
});
</script>
