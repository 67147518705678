import {
  ApiClientHelper,
  BasicUserService,
  Pageable,
  SimplePage,
  TableHeaderPageable,
  User,
} from '@apiida/controlplane-api-helper';
import userStore from '../../stores/UserStore';
import ApiClient from '../ApiClient';
import Scopes from '../../types/configuration/Scopes';
import AcceptedTermOfUse from '../../types/applications/AcceptedTermOfUse';
import TermOfUseAndVersion from '../../types/applications/TermOfUseAndVersion';

class UserService extends BasicUserService {
  constructor() {
    super(ApiClient);
  }

  async getAllUsersInOrganisation(
    pageable: Pageable,
    organisationId: number,
    header: Array<TableHeaderPageable | string> = []
  ): Promise<SimplePage<User>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/users?organization=${organisationId}`
    );
  }

  async getScops(): Promise<Scopes[] | null> {
    let result: Scopes[] | null = null;

    const response = await ApiClient.get<Scopes[]>(`/scopes`);

    if (response !== null) {
      result = response.data;
    }

    return result;
  }

  async getAcceptedTermsOfUseLog(id: number): Promise<AcceptedTermOfUse> {
    const response = await ApiClient.get<AcceptedTermOfUse>(`${this.url}/${id}/acceptedTermsOfUse`);
    return (
      response?.data ??
      ({
        acceptedBy: {} as User,
        acceptedVersions: [] as TermOfUseAndVersion[],
      } as AcceptedTermOfUse)
    );
  }

  async unlock(id: number): Promise<boolean> {
    const response = await ApiClient.post(`/users/${id}/unlock`);
    return ApiClientHelper.isSuccess(response);
  }

  storeFill(entitys: User[]): void {
    this.getStore().users = entitys;
  }

  storeGetAllEntitys(): User[] {
    return this.getStore().users;
  }

  getStore(): any {
    return userStore();
  }
}

export default new UserService();
