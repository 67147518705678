<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar id="IntegrationsSidebar" :navigation="tmpNavigation" />
</template>

<script lang="ts">
import { ArchiveBoxIcon, Cog6ToothIcon, HomeIcon, KeyIcon } from '@heroicons/vue/24/outline';
import {
  defineComponent,
  FunctionalComponent,
  HTMLAttributes,
  onMounted,
  ref,
  VNodeProps,
} from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';
import RoleGuards from '../../routers/guards/RoleGuards';

const navigation = [{ name: 'Overview', route: 'EnvironmentOverview', icon: HomeIcon }];

export default defineComponent({
  components: {
    BaseSidebar,
    SidebarCaption,
  },
  setup() {
    const tmpNavigation = ref<
      { name: string; route: string; icon: FunctionalComponent<HTMLAttributes & VNodeProps> }[]
    >([]);

    onMounted(async () => {
      tmpNavigation.value = [...navigation];
      if (RoleGuards.isGlobalAdmin()) {
        tmpNavigation.value.push({
          name: 'Deployments',
          route: 'EnvironmentDeployments',
          icon: ArchiveBoxIcon,
        });
        tmpNavigation.value.push({
          name: 'Access Management',
          route: 'EnvironmentAccessManagementSubscribe',
          icon: KeyIcon,
        });
        tmpNavigation.value.push({
          name: 'Settings',
          route: 'EnvironmentSettings',
          icon: Cog6ToothIcon,
        });
      }
    });

    return {
      tmpNavigation,
    };
  },
});
</script>
