<template>
  <span v-if="isBillingContact === false">
    <SidebarCaption>General</SidebarCaption>
    <BaseSidebar :navigation="itemsGeneral" />
  </span>

  <span v-if="config.enableTierAndBilling">
    <span v-if="isGlobalAdminOrBilling">
      <span v-if="isNotSaas === false">
        <SidebarCaption>Billing</SidebarCaption>
        <BaseSidebar :navigation="itemsBilling" />
      </span>
    </span>
  </span>

  <span v-if="isGlobalAdmin">
    <SidebarCaption>Subscriptions</SidebarCaption>
    <BaseSidebar :navigation="itemsSubscribtion" />

    <SidebarCaption>Integrations</SidebarCaption>
    <BaseSidebar id="IntegrationsSidebar" :navigation="itemsIntegrations" />

    <SidebarCaption>Authentication</SidebarCaption>
    <BaseSidebar :navigation="authIntegrations" />

    <SidebarCaption>Advanced settings</SidebarCaption>
    <BaseSidebar :navigation="advancedSettings" />
  </span>
</template>

<script lang="ts">
import {
  AcademicCapIcon,
  AdjustmentsVerticalIcon,
  ArrowsPointingInIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  CheckIcon,
  ClipboardDocumentIcon,
  ComputerDesktopIcon,
  CreditCardIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  KeyIcon,
  QueueListIcon,
  ShieldCheckIcon,
  SwatchIcon,
  UserIcon,
  LinkIcon,
} from '@heroicons/vue/24/outline';
import { defineComponent, onMounted, ref } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';

import RoleGuards from '../../routers/guards/RoleGuards';
import SaaSGuard from '../../routers/guards/SaaSGuard';
import config from '../../config';

const itemsSubscribtion = [
  { name: 'Approvals', route: 'ConfigurationApprovals', icon: CheckIcon },
  { name: 'Plans', route: 'ConfigurationPlansIndex', icon: ClipboardDocumentIcon },
];

const itemsIntegrations = [
  { name: 'Git Credentials', route: 'ConfigurationGitCredentialsIndex', icon: ShieldCheckIcon },
];

const itemsBilling = [
  { name: 'Billing Information', route: 'ConfigurationBillingInformation', icon: CreditCardIcon },
  { name: 'Tier Management', route: 'ConfigurationTierOverview', icon: BanknotesIcon },
];

const authIntegrations = [
  { name: 'SAML', route: 'ConfigurationSaml', icon: KeyIcon },
  { name: 'LDAP', route: 'ConfigurationLdap', icon: ComputerDesktopIcon },
];

const advancedSettings = [
  { name: 'Custom Fields', route: 'ConfigurationCustomApplicationFields', icon: SwatchIcon },
  {
    name: 'Discovery Settings',
    route: 'ConfigurationDiscoverySettings',
    icon: ArrowsPointingInIcon,
  },
  { name: 'Webhooks', route: 'ConfigurationWebhooksList', icon: LinkIcon },
];

export default defineComponent({
  computed: {
    config() {
      return config;
    },
  },
  components: { BaseSidebar, SidebarCaption },
  setup() {
    const itemsGeneral = ref<
      {
        name: string;
        route: string;
        icon: any;
      }[]
    >([]);
    const isGlobalAdmin = ref(false);
    const isGlobalAdminOrAdmin = ref(false);
    const isGlobalAdminOrBilling = ref(false);
    const isBillingContact = ref(false);
    const isNotSaas = ref(false);

    onMounted(async () => {
      isGlobalAdmin.value = RoleGuards.isGlobalAdmin();
      isGlobalAdminOrBilling.value = RoleGuards.isGlobalAdminOrBilling();
      isBillingContact.value = RoleGuards.isBillingContact();
      isGlobalAdminOrAdmin.value = RoleGuards.isAdminOrGlobalAdmin();
      isNotSaas.value = await SaaSGuard.isNotSaas();

      if (isGlobalAdmin.value) {
        itemsGeneral.value.push({
          name: 'General',
          route: 'ConfigurationGeneral',
          icon: GlobeAltIcon,
        });
        /*
        // TODO: This is the Customization option page for the Administration Portal (maybe for later)
        if (false) {
          // Not yet. First the Developer Portal
          itemsGeneral.value.push({
            name: 'Customization',
            route: 'ConfigurationCustomization',
            icon: PencilIcon,
          });
        }
        */
      }

      if (isBillingContact.value === false)
        itemsGeneral.value.push({
          name: 'Users',
          route: 'ConfigurationUsersIndex',
          icon: UserIcon,
        });

      if (isGlobalAdmin.value)
        itemsGeneral.value.push({
          name: 'Organizations',
          route: 'ConfigurationOrganizationsIndex',
          icon: BuildingOffice2Icon,
        });
      if (isGlobalAdmin.value && isNotSaas.value)
        itemsGeneral.value.push({ name: 'SMTP', route: 'ConfigurationSMTP', icon: EnvelopeIcon });
      if (isGlobalAdmin.value) {
        itemsGeneral.value.push({
          name: 'Developer Portals',
          route: 'ConfigurationDevPortalIndex',
          icon: AdjustmentsVerticalIcon,
        });
        itemsGeneral.value.push({
          name: 'Rulesets',
          route: 'ConfigurationRuleSetsIndex',
          icon: AcademicCapIcon,
        });
        itemsGeneral.value.push({
          name: 'Terms Of Use',
          route: 'ConfigurationTermsOfUseIndex',
          icon: DocumentTextIcon,
        });
      }
      if (isGlobalAdminOrAdmin.value)
        itemsGeneral.value.push({
          name: 'Audit Logs',
          route: 'ConfigurationAuditLogs',
          icon: QueueListIcon,
        });
    });

    return {
      isBillingContact,
      isGlobalAdminOrBilling,
      isNotSaas,
      isGlobalAdmin,
      itemsGeneral,
      itemsSubscribtion,
      itemsIntegrations,
      itemsBilling,
      authIntegrations,
      advancedSettings,
    };
  },
});
</script>
