import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';

export default [
  {
    path: '/apis',
    name: 'API',
    component: () => import('../views/EmptyPage.vue'),
    meta: { isEntity: true, BreadcrumbName: 'APIs' },
    beforeEnter: async (to, from, next) => {
      if (RoleGuards.isBillingContact()) {
        next('/welcome');
      }
      next();
    },
    props: (route) => ({
      ...route.params,
      ...{ apiId: Number(route.params.apiId) },
    }),
    children: [
      {
        path: '',
        name: 'APIs',
        component: () => import('../views/apis/ApiList.vue'),
      },
      {
        path: ':apiId',
        name: 'API',
        component: () => import('../views/apis/ApiDetails.vue'),
        children: [
          {
            path: '',
            name: 'ApiOverview',
            component: () => import('../views/apis/ApiOverview.vue'),
          },
          {
            path: 'apiProduct',
            name: 'ApiApiProduct',
            component: () => import('../views/apis/ApiApiProduct.vue'),
          },
          {
            path: 'analytics',
            name: 'ApiAnalytics',
            component: () => import('../views/apis/ApiAnalytics.vue'),
          },
          {
            path: 'deployments',
            name: 'ApiDeployments',
            component: () => import('../views/apis/ApiDeployments.vue'),
          },
          {
            path: 'versions',
            name: 'ApiVersionsBase',
            component: () => import('../views/EmptyPage.vue'),
            props: true,
            children: [
              {
                path: '',
                name: 'ApiVersions',
                component: () => import('../views/apis/ApiVersions.vue'),
              },
              {
                path: ':versionName',
                name: 'VersionDetails',
                component: () => import('../views/apis/versions/VersionTapSettingController.vue'),
                props: true,
                children: [
                  {
                    name: 'redirectToVersionSpecLinting',
                    path: '',
                    redirect: (to) => ({
                      name: 'VersionSpecLinting',
                      params: {
                        versionName: to.params.versionName,
                        apiId: to.params.apiId,
                      },
                    }),
                  },
                  {
                    path: 'specs',
                    name: 'VersionSpecLinting',
                    component: () => import('../views/apis/versions/VersionSpecLinting.vue'),
                  },
                  {
                    path: 'swaggerUi',
                    name: 'VersionSpecSwaggerUi',
                    component: () => import('../views/apis/versions/VersionSpecSwaggerUi.vue'),
                    props: true,
                  },
                  {
                    path: 'openProxy',
                    name: 'VersionOpenProxy',
                    component: () => import('../views/apis/versions/VersionOpenProxy.vue'),
                  },
                  {
                    path: 'settings',
                    name: 'VersionSettings',
                    component: () => import('../views/apis/versions/VersionSettings.vue'),
                  },
                ] as RouteRecordRaw[],
              },
            ] as RouteRecordRaw[],
          },
          {
            path: 'settings',
            name: 'ApiSettings',
            component: () => import('../views/apis/ApiSettings.vue'),
          },
          {
            path: 'documentation',
            name: 'ApiDocumentation',
            props: true,
            component: () => import('../views/apis/documentation/ApiDocList.vue'),
            children: [
              {
                path: '',
                name: 'ApiDocRootPage',
                component: () => import('../components/docPage/DocRootPage.vue'),
              },
              {
                path: 'docContent/:entityDocPageId',
                name: 'ApiDocPage',
                props: (route) => ({
                  ...route.params,
                  ...{ entityDocPageId: Number(route.params.entityDocPageId) },
                }),
                component: () => import('../views/documentation/DocPageView.vue'),
              },
            ] as RouteRecordRaw[],
          },
        ] as RouteRecordRaw[],
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
