import { DeploymentStatus } from '@apiida/controlplane-api-helper';

export default function getDeploymentStatus(deploymentStatus: DeploymentStatus): string {
  switch (deploymentStatus) {
    case DeploymentStatus.COMPLETE: {
      return 'Deployed';
    }
    case DeploymentStatus.DEPLOYMENT_FAILED: {
      return 'Deploy Incomplete';
    }
    case DeploymentStatus.DEPLOYMENT_IN_PROGRESS: {
      return 'Deploy in Progress';
    }
    case DeploymentStatus.DELETION_FAILED: {
      return 'Delete Incomplete';
    }
    case DeploymentStatus.DELETION_IN_PROGRESS: {
      return 'Delete in Progress';
    }
    case DeploymentStatus.DEPLOYMENT_REQUIRED: {
      return 'Out of Sync';
    }
    default: {
      return '';
    }
  }
}
