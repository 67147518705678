import { BasicAuthService } from '@apiida/controlplane-api-helper';
import apiClient from '../ApiClient';
import mainStore from '../../stores/MainStore';

class AuthService extends BasicAuthService {
  constructor() {
    super(apiClient);
  }

  getMainStore(): any {
    // @ts-ignore
    return mainStore(this.$pinia);
  }
}

export default new AuthService();
