import { ref } from 'vue';

export function checkDarkMode() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}
export const isDarkMode = ref(checkDarkMode());

export const handleColorSchemeChange = (e: MediaQueryListEvent) => {
  isDarkMode.value = e.matches;
};

// eslint-disable-next-line import/no-mutable-exports
export const colorSchemeQuery = ref<MediaQueryList | null>();
