import {
  ApiClientHelper,
  ExtraParamPageable,
  Pageable,
  SimplePage,
  TableHeaderPageable,
} from '@apiida/controlplane-api-helper';
import Application from '../../types/applications/Application';
import applicationStore from '../../stores/ApplicationStore';
import mainStore from '../../stores/MainStore';
import PaginationService from '../PaginationService';
import ApiClient from '../ApiClient';
import ApplicationDeployment from '../../types/applications/ApplicationDeployment';
import Subscription from '../../types/applications/Subscription';
import ApplicationCustomFieldContainer from '../../types/applications/ApplicationCustomFieldContainer';

class ApplicationService extends PaginationService<Application> {
  constructor() {
    super('/applications', 'Application');
  }

  getMainStore() {
    // @ts-ignore
    return mainStore(this.$pinia);
  }

  storeFill(entitys: Application[]): void {
    this.getStore().applications = entitys;
  }

  storeGetAllEntitys(): Application[] {
    return this.getStore().applications;
  }

  async canCreate(): Promise<boolean> {
    const response = await ApiClient.post<{ canCreateActive: boolean }>(`${this.url}/canCreate`);

    return response?.data.canCreateActive ?? false;
  }

  getStore(): any {
    return applicationStore();
  }

  async getApplicationDeployments(
    applicationId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false
  ): Promise<SimplePage<ApplicationDeployment>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${applicationId}/deployments`
    ) as unknown as SimplePage<ApplicationDeployment>;
  }

  async getSubscriptions(
    applicationId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false
  ): Promise<SimplePage<Subscription>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${applicationId}/subscriptions`
    ) as unknown as SimplePage<Subscription>;
  }

  async getCustomFields<T = ApplicationCustomFieldContainer>(
    applicationId: number,
    silent: boolean = false
  ): Promise<T> {
    const response = await ApiClient.get<T>(`${this.url}/${applicationId}/customFields`, silent);
    return response?.data ?? ({} as T);
  }

  async updateCustomFields<T = ApplicationCustomFieldContainer>(
    applicationId: number,
    data: T,
    silent: boolean = false
  ): Promise<boolean> {
    const response = await ApiClient.put<T>(
      `${this.url}/${applicationId}/customFields`,
      data,
      silent
    );
    return ApiClientHelper.isSuccess(response);
  }
}

export default new ApplicationService();
