<template>
  <div v-if="status === Status.INACTIVE" class="mt-4">
    <router-link :to="to">
      <div class="bg-red-50 hover:bg-red-200 rounded-md p-2 text-xs">
        <p class="font-medium text-red-600">{{ entity }} is not active!</p>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { Status } from '../../types/enums/Status';

export default defineComponent({
  components: {},
  props: {
    entity: {
      type: String as PropType<'API' | 'Environment' | 'Application' | 'DevPortal'>,
      required: true,
    },
    status: {
      type: String as PropType<Status>,
      default: '',
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: true,
    },
  },
  emits: ['to-overview'],
  setup() {
    return { Status };
  },
});
</script>
