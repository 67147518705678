import {
  ApiClientHelper,
  ExtraParamPageable,
  Pageable,
  SimplePage,
  TableHeaderPageable,
} from '@apiida/controlplane-api-helper';
import Api from '../../types/apis/Api';
import ApiClient from '../ApiClient';
import apiStore from '../../stores/ApiStore';
import ApiDeployment from '../../types/apis/ApiDeployment';
import mainStore from '../../stores/MainStore';
import PaginationServiceFileManagement from '../PaginationServiceFileManagement';

class ApiService extends PaginationServiceFileManagement<Api> {
  constructor() {
    super('/apis', 'API');
  }

  getMainStore() {
    // @ts-ignore
    return mainStore(this.$pinia);
  }

  async uploadSpecCreateOrUpdateVersion(apiId: number, file: any): Promise<boolean> {
    const fd = new FormData();
    fd.append('file', file, file.name);

    const response = await ApiClient.put(`/apis/${apiId}/file`, fd, false, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
    return ApiClientHelper.isSuccess(response);
  }

  async getApiDeployments(
    id: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false
  ): Promise<SimplePage<ApiDeployment>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${id}/deployments`
    ) as unknown as SimplePage<ApiDeployment>;
  }

  async canCreate(): Promise<boolean> {
    const response = await ApiClient.post<{ canCreateActive: boolean }>(`${this.url}/canCreate`);

    return response?.data.canCreateActive ?? false;
  }

  storeFill(entitys: Api[]): void {
    this.getStore().apis = entitys;
  }

  storeGetAllEntitys(): Api[] {
    return this.getStore().apis;
  }

  getStore(): any {
    return apiStore();
  }
}

export default new ApiService();
