import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';

export default [
  {
    path: '/governance',
    name: 'Governance',
    component: () => import('../views/governance/GovernanceBoard.vue'),
    beforeEnter: RoleGuards.isGlobalAdmin,
  },
] as RouteRecordRaw[];
