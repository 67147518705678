import { defineStore } from 'pinia';
import Environment from '../types/environments/Environment';
import { StageEnum } from '../types/enums/StageEnum';

const envStore = defineStore({
  id: 'environment',
  state: () => ({
    environments: [] as Environment[],
  }),
  getters: {
    getPinnedEnvironments(): Environment[] {
      return this.environments
        .filter((envInStore: Environment) => envInStore.pinned === true)
        .sort((a, b) => a.displayName.localeCompare(b.displayName));
    },
    getProdEnvironments(): Environment[] {
      return this.environments.filter(
        (envInStore: Environment) => envInStore.stage === StageEnum.PRODUCTION
      );
    },
    getNonProdEnvironments(): Environment[] {
      return this.environments.filter(
        (envInStore: Environment) => envInStore.stage !== StageEnum.PRODUCTION
      );
    },
  },
  actions: {
    setEnvironment(environment: Environment): void {
      this.environments.map((env) =>
        Number(env.id) === Number(environment.id) ? environment : env
      );
    },
    getEnvironmentById(environmentId: number): Environment | undefined {
      const env: Environment | undefined = this.environments.find(
        (environmentInArray: Environment) => Number(environmentInArray.id) === Number(environmentId)
      );

      return env;
    },
  },
});

export default envStore;
