<template>
  <BaseBadge v-if="agentStatus === AgentStatus.NOT_CONFIGURED">No Agent</BaseBadge>

  <WarningBadge
    v-if="
      agentStatus === AgentStatus.CONNECTED && gatewayInfo?.status !== GatewayInfoStatus.CONNECTED
    "
  >
    <BoltSlashIcon class="h-3 w-3 mr-1.5 ml-1 my-auto" />
    <div v-if="withText" class="my-0.5 mr-0.5" :title="gatewayInfoText">Communication Error</div>
  </WarningBadge>

  <SuccessBadge
    v-if="
      agentStatus === AgentStatus.CONNECTED && gatewayInfo?.status === GatewayInfoStatus.CONNECTED
    "
  >
    <BoltIcon class="h-3 w-3 mr-1 mt-1 text-exo-color-background-success-strong" />
    <div v-if="withText" class="!text-exo-color-background-success-strong-hover">Connected</div>
  </SuccessBadge>

  <DangerBadge v-if="agentStatus === AgentStatus.NOT_CONNECTED">
    <BoltSlashIcon class="h-3 w-3 mr-1 mt-1" />
    <div v-if="withText">Agent Offline</div>
  </DangerBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { BoltIcon, BoltSlashIcon } from '@heroicons/vue/24/outline';
import { BaseBadge, DangerBadge, SuccessBadge, WarningBadge } from '@apiida/vue-components';
import { GatewayInfoStatus } from '../../types/enums/GatewayInfoStatus';
import GatewayInfo from '../../types/agent/GatewayInfo';
import { AgentStatus } from '../../types/enums/AgentStatus';

export default defineComponent({
  components: {
    BoltIcon,
    DangerBadge,
    SuccessBadge,
    BaseBadge,
    WarningBadge,
    BoltSlashIcon,
  },

  props: {
    agentStatus: {
      type: String,
      default: AgentStatus.NOT_CONFIGURED,
    },
    gatewayInfo: {
      type: Object as PropType<GatewayInfo>,
      default: () => {},
    },
    withText: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const gatewayInfoText = computed(() => props.gatewayInfo?.errors?.join());

    return {
      AgentStatus,
      GatewayInfoStatus,
      gatewayInfoText,
    };
  },
});
</script>
