<template>
  <div class="mt-4">
    <router-link :to="to">
      <div v-if="status === DeploymentStatus.DEPLOYMENT_FAILED">
        <div class="bg-red-50 hover:bg-red-200 rounded-md p-2 text-xs">
          <p class="font-medium text-red-600">Deployment was not successful!</p>
        </div>
      </div>
      <div v-if="status === DeploymentStatus.DEPLOYMENT_REQUIRED">
        <div class="bg-red-50 hover:bg-red-200 rounded-md p-2 text-xs">
          <p class="font-medium text-red-600">Deployment is out of sync!</p>
        </div>
      </div>
      <div v-if="status === DeploymentStatus.DELETION_FAILED">
        <div class="bg-red-50 hover:bg-red-200 rounded-md p-2 text-xs">
          <p class="font-medium text-red-600">Deletion was not successful!</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { DeploymentStatus } from '@apiida/controlplane-api-helper';
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  components: {},
  props: {
    status: {
      type: String as PropType<DeploymentStatus>,
      default: '',
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: true,
    },
  },
  emits: ['to-overview'],
  setup() {
    return { DeploymentStatus };
  },
});
</script>
