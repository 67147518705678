import config from '../config';

// eslint-disable-next-line import/prefer-default-export
export function insertTenantId(url: string): string {
  let tmpUrl = url;

  // do not change this. see readme
  if (!config.insertTenantId.includes('addTenantIdToBack')) {
    return tmpUrl;
  }

  const subdomain = window.location.host.split('.')[1]
    ? `${window.location.host.split('.')[0]}.`
    : undefined;

  if (subdomain) {
    const urlParts = url.split('://');

    // If we develop local in SaaS mode, we have to add the subdomain dev, because you cannot issue certificates for *.localhost.
    if (urlParts[1].includes('localhost:')) {
      urlParts[1] = `dev.${urlParts[1]}`;
    }

    tmpUrl = `${urlParts[0]}://${subdomain}${urlParts[1]}`;
  }

  return tmpUrl;
}
