<template>
  <MultiColumnLayout :hide-bar-by-small-resolution="hideBarBySmallResolution">
    <template #sidebar>
      <IconAvatar title="Configuration" />
      <ConfigurationSidebar />
    </template>
    <slot />
  </MultiColumnLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IconAvatar } from '@apiida/vue-components';
import ConfigurationSidebar from './ConfigurationSidebar.vue';

export default defineComponent({
  components: {
    ConfigurationSidebar,
    IconAvatar,
  },
  props: {
    hideBarBySmallResolution: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
