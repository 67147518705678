import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/auth/LoginScreen.vue'),
  },
  {
    path: '/samlResponse/:samlOTToken',
    name: 'LoggedIn',
  },
  {
    path: '/acceptInvitation/:token',
    name: 'AcceptInvitation',
    component: () => import('../views/auth/AcceptInvitation.vue'),
  },
  {
    path: '/resetPassword/:token',
    name: 'ResetPassword',
    component: () => import('../views/auth/AcceptInvitation.vue'),
  },
] as RouteRecordRaw[];
