import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';
import SaaSGuard from './guards/SaaSGuard';

export default [
  /* TODO: config.enableTierAndBilling */
  /*   {
    path: '/tier',
    name: 'TierView',
    beforeEnter: [RoleGuards.isGlobalAdminOrBilling, SaaSGuard.isSaas],
    component: () => import('../views/TierView.vue'),
  }, */
] as RouteRecordRaw[];
