<template>
  <span :title="getDeploymentStatus(status)">
    <svg
      :class="['inline mr-1 h-3 w-3 mt-[-1px]', getDeploymentStatusColor(status)]"
      fill="currentColor"
      viewBox="0 0 8 8"
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
    <span v-if="showDeployStatusText"> {{ getDeploymentStatus(status) }} </span>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { DeploymentStatus } from '@apiida/controlplane-api-helper';
import getDeploymentStatus from '../../helper/DeploymentStatusHelper';

export default defineComponent({
  components: {},
  props: {
    status: {
      type: String as PropType<DeploymentStatus>,
      default: '',
    },
    showDeployStatusText: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    function getDeploymentStatusColor(deploymentStatus: DeploymentStatus): string {
      switch (deploymentStatus) {
        case DeploymentStatus.COMPLETE: {
          return 'text-green-500';
        }
        case DeploymentStatus.DEPLOYMENT_IN_PROGRESS: {
          return 'text-yellow-500';
        }
        case DeploymentStatus.DELETION_IN_PROGRESS: {
          return 'text-yellow-500';
        }
        case DeploymentStatus.DEPLOYMENT_FAILED: {
          return 'text-red-500';
        }
        case DeploymentStatus.DELETION_FAILED: {
          return 'text-red-500';
        }
        case DeploymentStatus.DEPLOYMENT_REQUIRED: {
          return 'text-red-500';
        }
        default: {
          return '';
        }
      }
    }

    return { DeploymentStatus, getDeploymentStatus, getDeploymentStatusColor };
  },
});
</script>
