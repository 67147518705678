import { Job } from '@apiida/controlplane-api-helper';
import Api from '../types/apis/Api';
import Environment from '../types/environments/Environment';
import apiStore from '../stores/ApiStore';
import ApiVersionService from '../services/apis/ApiVersionService';

export default async function apiDeployErrorHandler(
  api: Api,
  environment: Environment,
  versionName: string,
  job: any
) {
  if (job as Job) {
    if (job.errorCustomCode) {
      const aStore = apiStore();
      if (job.errorCustomCode === 'NAME_CONFLICT') {
        const apiVersion = await ApiVersionService.get(api.id, versionName);
        aStore.deployApiObjct.api = api;
        aStore.deployApiObjct.environment = environment;
        aStore.deployApiObjct.version = apiVersion;
        aStore.showDeployErrorDialog = true;

        aStore.showDeployErrorDialog = true;
      } else {
        aStore.cleanDeployApiObjct();
      }
    }
  }
}
