import { defineStore } from 'pinia';
import CustomApplicationFields from '../types/configuration/CustomApplicationFields';

const customApplicationFieldsStore = defineStore({
  id: 'customApplicationFields',
  state: () => ({
    customApplicationFields: [] as CustomApplicationFields[],
  }),
});

export default customApplicationFieldsStore;
